<template>
  <div class="md:bg-purple-300 h-screen">
    <div class="grid grid-cols-1 md:grid-cols-2">
      <div class="h-screen md:flex items-center justify-center hidden">
        <div class="flex flex-col h-full">
          <div class="py-4 px-10">
            <router-link :to="{ name: 'home' }">
              <h1
                class="
                  hidden
                  md:flex
                  text-white
                  hover:text-gray-700
                  font-extrabold
                  text-base
                  md:text-2xl
                "
                style="font-family: 'Gluten', cursive"
              >
                putzfome
              </h1>
            </router-link>
          </div>
          <div class="hidden items-center justify-center md:flex h-full w-full">
            <div class="lg:w-1/2">
              <img src="@/assets/images/take-away-pana2.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col md:h-auto h-screen">
        <div class="bg-white w-full p-2 flex flex-1">
          <div class="w-full px-6 md:px-8 lg:w-9/12 mx-auto flex items-center">
            <div class="w-full space-y-8">
              <div class="md:hidden">
                <router-link :to="{ name: 'home' }">

                  <h1
                    class="
                      text-center text-gray-800
                      hover:text-gray-700
                      font-extrabold
                      text-2xl
                    "
                    style="font-family: 'Gluten', cursive"
                  >
                    putzfome
                  </h1>
                </router-link>
              </div>
              <div>
                <h2
                  class="mt-6 text-lg md:text-3xl font-extrabold text-gray-900"
                >
                  Informe seu e-mail
                </h2>
              </div>
              <form
                @submit.prevent="forgotPasswordClient"
                class="mt-8 space-y-6"
              >
                <div class="rounded-md shadow-sm space-y-6">
                  <div class="">
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      v-model="formData.email"
                      autocomplete="email"
                      placeholder="Digite seu e-mail"
                      class="
                        block
                        w-full
                        px-5
                        py-3
                        text-base text-neutral-600
                        placeholder-gray-300
                        transition
                        duration-500
                        ease-in-out
                        transform
                        border border-transparent
                        rounded-lg
                        bg-gray-50
                        focus:outline-none
                        focus:border-transparent
                        focus:ring-2
                        focus:ring-white
                        focus:ring-offset-2
                        focus:ring-offset-gray-300
                      "
                      :class="{ 'border border-red-600 ': hasError('email') }"
                      
                    />

                    <div
                      class="text-red-600"
                      :class="{
                        'bg-red-50': hasError('password_confirmation'),
                      }"
                    >
                      {{ getError("email") }}
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    class="
                      group
                      relative
                      w-full
                      flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-indigo-500
                      hover:bg-indigo-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                    "
                    :disabled="loading"
                  >
                    <span
                      class="absolute left-0 inset-y-0 flex items-center pl-3"
                    >
                      <!-- Heroicon name: solid/lock-closed -->
                      <svg
                        class="
                          h-5
                          w-5
                          text-indigo-500
                          group-hover:text-indigo-400
                        "
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                    <span v-if="loading" class="flex items-center">
                      <img
                        src="@/assets/images/preloader.gif"
                        alt=""
                        class="w-5 h-5 mr-2"
                      />
                      aguarde um momento
                    </span>
                    <span v-else>Enviar</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,

      formData: {
        email: "",
      },
      errors: null,
    };
  },
  methods: {
    ...mapActions(["forgotPassword"]),

    forgotPasswordClient() {
      this.reset();
      this.loading = true;

      this.forgotPassword(this.formData)
        .then((response) => {
          // this.$router.push({ name: "check.token.forgotPassword" });
          this.$router.push({ name: "check.token.forgotPassword", params: { email: this.formData.email }}  );
          
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            icon: "error",
            title: "Dados invalídos",
          });

          return;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.errors = null;
    },

    hasError(field) {
      return this.errors
    },
    getError(field) {
      if (this.hasError(field)) {
        return this.errors[field][0];
      }
    },
  },
};
</script>